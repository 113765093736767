// Web standard
export const loadResizeObserverPolyfill = async () => {
  if (!window.ResizeObserver) {
    const { ResizeObserver } = await import(
      '@juggle/resize-observer' /* webpackChunkName: "polyfill-resize-observer" */
    );
    window.ResizeObserver = ResizeObserver;
  }
};

export const loadElementScrollPolyfill = async () => {
  if (
    !Element.prototype.scroll ||
    !Element.prototype.scrollBy ||
    !Element.prototype.scrollTo
  ) {
    await import(
      // @ts-expect-error no type definition for element-scroll-polyfill
      'element-scroll-polyfill' /* webpackChunkName: "polyfill-element-scroll" */
    );
  }
};

export const loadObjectFromEntriesPolyfill = async () => {
  if (!Object.fromEntries) {
    await import(
      // @ts-expect-error no type definition for core-js/es/object/from-entries
      'core-js/es/object/from-entries' /* webpackChunkName: "polyfill-object-fromentries" */
    );
  }
};

export const loadFindLastIndexPolyfill = async () => {
  if (!Array.prototype.findLastIndex) {
    await import(
      // @ts-expect-error no type definition for core-js/es/array/find-last-index
      'core-js/es/array/find-last-index' /* webpackChunkName: "polyfill-findlastindex" */
    );
  }
};

export const loadFlatMapPolyfill = async () => {
  if (!Array.prototype.flatMap) {
    await import(
      // @ts-expect-error no type definition for core-js/es/array/flat-map
      'core-js/es/array/flat-map' /* webpackChunkName: "polyfill-flatmap" */
    );
  }
};

export const loadWebStreamsPolyfill = async () => {
  if (typeof TransformStream === 'undefined') {
    await import(
      'web-streams-polyfill/polyfill' /* webpackChunkName: "polyfill-web-streams" */
    );
  }
};

export const loadWithResolversPolyfill = async () => {
  // eslint-disable-next-line typescript-compat/compat -- feature detection
  if (typeof Promise.withResolvers !== 'function') {
    await import(
      // @ts-expect-error no type definition for @ungap/with-resolvers
      '@ungap/with-resolvers' /* webpackChunkName: "polyfill-with-resolvers" */
    );
  }
};
